import { useEffect, useState } from 'react'
import { Button, Col, InputGroup, Form, Nav, Row, Tab } from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'

import Cashier from './Cashier'
import GamblerCashier from './GamblerCashier'
import Icon from '../utils/Icon'
import { fetcher, getToday } from '../../utils'
import Navbar from "../SportsPage/Navbar";

export default function DealerAccount({
  axios,
  setLoading,
  sport,
  onChangeSport,
  isMobile,
  onItemClick,
  settings,
  onError,
  isDarkTheme
}) {
  const today = getToday()

  const [startDate, setStartDate] = useState(moment().isoWeekday(1).format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(today)
  const [key, setKey] = useState('geral')
  const [data, setData] = useState({})

  const loadData = () => {
    setLoading(true)
    const body = {
      inicio: startDate,
      fim: finalDate,
      tipo: key
    }

    fetcher(axios, '/api/caixa', body)
      .then(({ data }) => {
        setLoading(false)
        if (data) setData(data)
      }).catch(onError)
  }

  useEffect(() => {
    loadData()
  }, [key])

  const renderContent = () => {
    return (
      <div className="p-2">
        <Tab.Container activeKey={key} onSelect={(selectedKey) => setKey(selectedKey)}>
          <div className={classnames({ 'mx-3': !isMobile })}>
            <Row className={classnames({ 'ml-0 my-3': !isMobile })}>
              <Col className={classnames({ 'pl-0': !isMobile })}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                      <Icon name="calendar" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                    type="date"
                    value={startDate}
                    onChange={({target}) => setStartDate(target.value)}
                  />
                </InputGroup>
              </Col>
              {isMobile && <div className="w-100 mt-2"></div>}
              <Col className={classnames({ 'pl-0': !isMobile })}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                      <Icon name="calendar" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                    type="date"
                    value={finalDate}
                    onChange={({target}) => setFinalDate(target.value)}
                  />
                </InputGroup>
              </Col>
              {isMobile && <div className="w-100 mt-2"></div>}
              <Col>
                <Button variant="secondary" block onClick={() => loadData()}>
                  <Icon name="search" margin />
                  Buscar
                </Button>
              </Col>
            </Row>
          </div>
          <Nav variant="pills" className={classnames({ 'nav-justified': !isMobile })}>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile })}>
              <Nav.Link eventKey="geral">
                <Icon name="coins" margin />
                Geral
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile })}>
              <Nav.Link eventKey="ao_vivo">
                <Icon name="signal-stream" margin />
                Ao Vivo
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className={classnames({ 'bg-dark': isDarkTheme, 'w-100 mt-2': isMobile })}>
              <Nav.Link eventKey="apostador">
                <Icon name="user" margin />
                Apostador
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="mt-3">
            <Tab.Pane eventKey="geral">
              <Cashier
                isDarkTheme={isDarkTheme}
                type="geral"
                data={data}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="ao_vivo">
              <Cashier
                isDarkTheme={isDarkTheme}
                type="ao_vivo"
                data={data}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="apostador">
              <GamblerCashier
                isDarkTheme={isDarkTheme}
                type={key}
                data={data}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className="home-m-wrapper">
        <div className="scroll-mobile">
          <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
          {renderContent()}
        </div>
      </div>
    )
  }

  return (
    <>
      <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
      <div className="home-wrapper">
        {renderContent()}
      </div>
    </>
  )
}
