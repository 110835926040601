import classnames from 'classnames'
import { useState, useEffect, useCallback } from 'react'
import {
  Alert, Badge, Button, ButtonGroup, Card,
  Col, Dropdown, InputGroup, FormControl, Nav, Row, Tab
} from 'react-bootstrap'
import moment from 'moment'

import ColAuto from '../utils/ColAuto'
import Icon from '../utils/Icon'
import PageSpinner from '../layout/PageSpinner'
import {
  getToday, desktopPrint, callNative,
  generatePrintContent, fetcher, getLocalDate,
  formatNumber
} from '../../utils'
import Navbar from "../SportsPage/Navbar";

export default function ListBets({ isApp, axios, user, settings, onShareTicket, isMobile, isDarkTheme, sport, canPrintTicket, onChangeSport, onShowTicket, onItemClick, onError }) {
  const today = getToday()
  const [type, setType] = useState(1)
  const [bets, setBets] = useState([])
  const [query, setQuery] = useState('')
  const [filteredBets, setFilteredBets] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const [startDate, setStartDate] = useState(moment().isoWeekday(1).format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(today)
  const [code, setCode] = useState('')
  const [gamblers, setGamblers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setFilteredBets([])
    loadBets()
    setQuery('')
  }, [type])

  useEffect(() => {
    let i

    if (type == 1 && bets.length > 0) {
      i = setInterval(() => {
        loadBets(type, true)
      }, 30000)
    }

    return () => clearInterval(i)
  }, [type, bets])

  useEffect(() => {
    handleInput(query)
  }, [query])

  const handleSelect = (gambler) => {
    if (gambler) {
      setQuery(gambler)
      const _bets = bets.filter(v => v.aposta.nome_apostador === gambler);
      setFilteredBets(_bets)
    } else {
      setFilteredBets(bets)
    }
  }

  const handleInput = (query) => {
    if (query) {
      const _bets = bets.filter(v => v.aposta.nome_apostador.includes(query) || v.aposta.cod_aposta.includes(query));
      setFilteredBets(_bets)
    } else {
      setFilteredBets(bets)
    }
  }

  const loadBets = useCallback((silenceLoad = false) => {
    if (!silenceLoad) {
      setLoading(true)
    }

    const body = {
      tipo: type,
      data: startDate + '/' + finalDate,
      codigo: code
    }

    fetcher(axios, '/api/apostas', body)
      .then(({ data }) => {
        if (!data) {
          data = []
        }

        setBets(data)
        setFilteredBets(data)
        setGamblers([...new Set(data.map(({ aposta }) => aposta.nome_apostador))])

        if (!silenceLoad) {
          setLoading(false)
        }
      }).catch(onError)
  }, [type, startDate, finalDate, code])

  const searchBet = () => {
    loadBets()
    if (isSearch) setIsSearch(false)
  }

  const printTicket = (code) => {
    fetcher(axios, '/api/bilhete', `cod=${code}`)
      .then(({ data }) => {
        if (!canPrintTicket(data)) {
          alertify.alert('Atenção!', 'Não é possível imprimir o bilhete!')
          return
        }

        if (isApp) {
          if (data.BILHETE && !data.BILHETE.retorno_real) {
            data.BILHETE.retorno_real = ''
          }

          callNative('printTicket', data)
        } else {
          desktopPrint(generatePrintContent(data.BILHETE, data.ITENS, settings))
        }
      }).catch(onError)
  }

  const cancelBet = (code) => {
    alertify.prompt(
      'Cancelar Aposta', 'Por favor, informe sua senha', '',
      (e, value) => {
        if (!value) {
          e.cancel = true
          return
        }

        setLoading(true)
        const body = { opcao: 4, cod: code, senha: value }
        fetcher(axios, '/api/cancelarAposta', body)
          .then(({ data }) => {
            if (data.resposta) {
              alertify.alert("Cancelado com Sucesso!", '')
                .set({ padding: true, labels: { ok: 'Fechar' } })
            } else {
              if (data.reconfirmar) {
                alertify.confirm(data.mensagem, data.descricao, () => validateBet(code), () => { })
                  .set({ padding: true, labels: { ok: 'Tentar Novamente', cancel: 'Cancelar' } })
              } else {
                alertify.alert(data.mensagem, data.descricao)
              }
            }

            loadBets()
          }).catch(onError)
      },
      () => { }
    ).set({ type: 'password', labels: { ok: 'Confirmar', cancel: 'Voltar' }})
  }

  const isPrintEnabled = () => {
    return settings.reimprimir_aposta || settings.reimprimir_ultima_aposta
  }

  const isCancelEnabled = () => {
    return settings.cancelar_aposta || settings.cancelar_ultima_aposta
  }

  const finishBet = (code) => {
    const body = {
      aposta: code
    }

    fetcher(axios, '/api/encerramento', body)
      .then(({ data }) => {
        alertify.confirm(
          data.mensagem, data.descricao, () => {
            body['confirmacao'] = 1

            fetcher(axios, '/api/encerramento', body)
              .then(({ data }) => {
                if (data.resposta) {
                  alertify.alert('Sucesso!', 'Sua aposta foi encerrada com sucesso')
                    .set({ padding: true, labels: { ok: 'Fechar' } })
                  loadBets()
                } else {
                  alertify.alert(data.mensagem, data.descricao)
                }
              }).catch(onError)
          }, () => { }
        )
          .set({ padding: true, labels: { ok: 'Confirmar', cancel: 'Cancelar' }})
      }).catch(onError)
  }

  const renderCashout = (bet) => {
    if (!settings.habilitar_cashout || type != 1 || (user.nivel == 2 && !settings.habilitar_cashout_cambista)) {
      return null
    }

    if (isMobile) {
      return <Button variant="secondary" block onClick={() => finishBet(bet.cod_aposta)} className="mt-2" disabled={!bet.pode_encerrar}>
        <Icon name="times" margin />
        Encerrar Aposta {bet.novo_premio ? ` - R$ ${formatNumber(bet.novo_premio)}` : ''}
      </Button>
    }

    return <>
      <Dropdown.Divider />
      {settings.habilitar_cashout && type == 1 && <Dropdown.Item onClick={() => finishBet(bet.cod_aposta)} disabled={!bet.pode_encerrar}>
        <Icon name="times" margin />
        Encerrar
        {bet.novo_premio && <p className="mt-1 mb-0">
          R$ {formatNumber(bet.novo_premio)}
        </p>}
      </Dropdown.Item>}
    </>
  }

  const renderData = (data) => {
    if (isMobile) {
      const isFinalized = type != 2 && type != 3

      return data.map((bet) => (
        <Card key={bet.aposta.cod_aposta} className={classnames('m-2', {
          'text-white': isDarkTheme || isFinalized,
          'bg-dark': isDarkTheme && isFinalized,
          'bg-gray': !isDarkTheme && isFinalized,
          'bg-success': type == 2,
          'bg-danger': type == 3
        })}>
          <Card.Header>
            <Row>
              <ColAuto className="text-uppercase text-truncate">
                <Icon name="hashtag" margin /> {bet.aposta.cod_aposta}
              </ColAuto>
              <Col className="text-right text-truncate">
                <Icon name="calendar" margin /> {getLocalDate(bet.aposta.data).format('DD/MM HH:mm')}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col className="text-truncate">
                Apostador
              </Col>
              <Col className="text-truncate">
                {bet.aposta.nome_apostador}
              </Col>
            </Row>
            <Row>
              <Col className="text-truncate">
                Valor
              </Col>
              <Col className="text-truncate">
                R$ {formatNumber(bet.aposta.valor_aposta)}
              </Col>
            </Row>
            <Row>
              <Col className="text-truncate">
                Retorno
              </Col>
              <Col className="text-truncate">
                R$ {formatNumber(bet.aposta.premio)}
              </Col>
            </Row>
            <Row>
              <Col className="text-truncate">
                Palpites
              </Col>
              <Col className="text-truncate">
                {bet.palpites}
              </Col>
            </Row>
            {renderCashout(bet.aposta)}
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col onClick={() => onShowTicket(bet.aposta.cod_aposta)}>
                <div className="w-100 text-center">
                  <Icon name="eye" />
                </div>
              </Col>
              {type == 1 && isCancelEnabled() && <Col onClick={() => cancelBet(bet.aposta.cod_aposta)}>
                <div className="w-100 text-center">
                  <Icon name="ban" />
                </div>
              </Col>}
              {isApp && type == 1 && isPrintEnabled() && <Col onClick={() => printTicket(bet.aposta.cod_aposta)}>
                <div className="w-100 text-center">
                  <Icon name="print" />
                </div>
              </Col>}
              {settings.compartilhar_comprovante && <Col onClick={() => onShareTicket(bet.aposta.cod_aposta, bet.aposta.valor_aposta)}>
                <div className="w-100 text-center">
                  <Icon name="share" />
                </div>
              </Col>}
            </Row>
          </Card.Footer>
        </Card>
      ))
    }

    return (
      <>
        <Row className="row-head mx-2">
          <Col className="text-truncate text-center">
            <Icon name="hashtag" margin />
            Código
          </Col>
          <Col className="text-truncate text-center">
            <Icon name="calendar" margin />
            Horário
          </Col>
          <Col className="text-truncate text-center">
            <Icon name="user" margin />
            Apostador
          </Col>
          <Col className="text-truncate text-center">
            <Icon name="dollar-sign" margin />
            Valor
          </Col>
          <Col className="text-truncate text-center">
            <Icon name="exchange-alt" margin />
            Retorno
          </Col>
          {user.nivel == 2 && <Col className="text-truncate text-center">
            <Icon name="circle-dollar" margin />
            Comissão
          </Col>}
          <Col className="text-truncate text-center">
            <Icon name="asterisk" margin />
            Palpites
          </Col>
          <Col className="text-truncate text-center">
            <Icon name="cogs" margin />
            Opções
          </Col>
        </Row>
        <div className="virtual-scroll mx-2 h-100" style={{ maxHeight: '72%' }}>
          {data.map((bet) => (
            <Row className="row-item" key={bet.aposta.cod_aposta}>
              <Col className={classnames('text-truncate text-center text-uppercase top-2', {
                'text-light': (type == 1 || type > 3) && isDarkTheme,
                'text-success': type == 2,
                'text-danger': type == 3
              })}>
                {bet.aposta.cod_aposta}
              </Col>
              <Col className="text-truncate text-center text-uppercase top-2">
                {getLocalDate(bet.aposta.data).format('DD/MM HH:mm')}
              </Col>
              <Col className="text-truncate text-center top-2">
                {bet.aposta.nome_apostador}
              </Col>
              <Col className="text-truncate text-center top-2">
                R$ {formatNumber(bet.aposta.valor_aposta)}
              </Col>
              <Col className="text-truncate text-center top-2">
                R$ {formatNumber(bet.aposta.premio)}
              </Col>
              {user.nivel == 2 && <Col className="text-truncate text-center top-2">
                R$ {formatNumber(bet.aposta.comissao_cambista)}
              </Col>}
              <Col className="text-truncate text-center top-2">
                {bet.palpites}
              </Col>
              <Col className="text-center">
                <Dropdown as={ButtonGroup} className="btn-block">
                  <Dropdown.Toggle variant={isDarkTheme ? 'dark' : ''} size="sm" block className={classnames({ 'text-light': isDarkTheme })}>
                    <Icon name="ellipsis-v" margin />
                    Mais
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Header>{bet.aposta.cod_aposta}</Dropdown.Header>
                    <Dropdown.Item onClick={() => onShowTicket(bet.aposta.cod_aposta)}>
                      <Icon name="eye" margin />
                      Ver
                    </Dropdown.Item>
                    {type == 1 && isCancelEnabled() && <Dropdown.Item onClick={() => cancelBet(bet.aposta.cod_aposta)}>
                      <Icon name="ban" margin />
                      Cancelar
                    </Dropdown.Item>}
                    {type == 1 && isPrintEnabled() && <Dropdown.Item onClick={() => printTicket(bet.aposta.cod_aposta)}>
                      <Icon name="print" margin />
                      Imprimir
                    </Dropdown.Item>}
                    {settings.compartilhar_comprovante && <Dropdown.Item onClick={() => onShareTicket(bet.aposta.cod_aposta, bet.aposta.valor_aposta)}>
                      <Icon name="share" margin />
                      Compartilhar
                    </Dropdown.Item>}
                    {renderCashout(bet.aposta)}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          ))}
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (filteredBets.length > 0) {
      return (
        <>
          <div className="ml-2 mr-2 mb-2">
            <Row>
              <Col className={classnames({ 'pr-0': !isMobile })}>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                      <Icon name="user" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    as="select"
                    custom
                    className={classnames(['border-0 form-control', {
                      'bg-dark text-light': isDarkTheme,
                    }])}
                    onChange={({ target }) => handleSelect(target.value)}
                  >
                    <option value="">Selecionar apostador</option>
                    {bets.length > 0 && gamblers.map(gambler => <option key={gambler}>{gambler}</option>)}
                  </FormControl>
                </InputGroup>
              </Col>
              {isMobile && <div className="w-100 mt-2"></div>}
              <Col>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                      <Icon name="search" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    autoComplete="off"
                    className={classnames(['border-0', {
                      'bg-dark text-light': isDarkTheme,
                    }])}
                    placeholder="Buscar por apostador, código..."
                    spellCheck={false}
                    value={query}
                    onChange={({ target }) => setQuery(target.value)}
                  />
                </InputGroup>
              </Col>
            </Row>
          </div>
          {renderData(filteredBets)}
        </>
      )
    }

    return (
      <Alert className={classnames(['m-2', {
        'alert-dark2': isDarkTheme,
        'bg-white': !isDarkTheme
      }])}>
        <Alert.Heading>Ops, ocorreu um problema!</Alert.Heading>
        <hr />
        <p className="mb-0">
          Não foi possível achar nenhuma aposta para da data informada!
        </p>
        <hr />
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="calendar" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={classnames(['border-0', {
                  'bg-dark text-light': isDarkTheme,
                }])}
                type="date"
                value={startDate}
                onChange={({ target }) => setStartDate(target.value)}
              />
            </InputGroup>
          </Col>
          {isMobile && <div className="w-100 mt-2"></div>}
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="calendar" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={classnames(['border-0', {
                  'bg-dark text-light': isDarkTheme,
                }])}
                type="date"
                value={finalDate}
                onChange={({ target }) => setFinalDate(target.value)}
              />
            </InputGroup>
          </Col>
          {isMobile && <div className="w-100 mt-2"></div>}
          <Col>
            <Button variant="secondary" block onClick={() => searchBet()}>
              <Icon name="search" margin />
              Buscar
            </Button>
          </Col>
        </Row>
      </Alert>
    )
  }

  const renderMain = () => {
    if (isSearch) {
      return (
        <Tab.Container defaultActiveKey="date">
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="ml-2 mr-2">
                <Nav.Item>
                  <Nav.Link eventKey="date">
                    <Icon name="calendar" margin />
                    Por data
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="code">
                    <Icon name="receipt" margin />
                    Por código
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="date">
                  <Alert className={classnames(['m-2', {
                    'alert-dark2': isDarkTheme,
                    'alert-light': !isDarkTheme
                  }])}>
                    <Alert.Heading>Buscar apostas!</Alert.Heading>
                    <hr />
                    <p className="mb-0">
                      Informe abaixo o intervalo de datas!
                    </p>
                    <hr />
                    <Row>
                      <Col>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                              <Icon name="calendar" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            className={classnames(['border-0', {
                              'bg-dark text-light': isDarkTheme,
                            }])}
                            type="date"
                            value={startDate}
                            onChange={({ target }) => setStartDate(target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <div className="w-100 mt-2"></div>
                      <Col>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                              <Icon name="calendar" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            className={classnames(['border-0', {
                              'bg-dark text-light': isDarkTheme,
                            }])}
                            type="date"
                            value={finalDate}
                            onChange={({ target }) => setFinalDate(target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <div className="w-100 mt-2"></div>
                      <Col>
                        <Button variant="primary" block onClick={() => searchBet()}>
                          <Icon name="search" margin />
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Alert>
                </Tab.Pane>
                <Tab.Pane eventKey="code">
                  <Alert className={classnames(['m-2', {
                    'alert-dark2': isDarkTheme,
                    'alert-light': !isDarkTheme
                  }])}>
                    <Alert.Heading>Buscar apostas!</Alert.Heading>
                    <hr />
                    <p className="mb-0">
                      Informe abaixo o código da aposta!
                    </p>
                    <hr />
                    <Row>
                      <Col>
                        <InputGroup>
                          <InputGroup.Prepend>
                            <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                              <Icon name="receipt" />
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                          <FormControl
                            autoComplete="off"
                            className={classnames(['border-0', {
                              'bg-dark text-light': isDarkTheme,
                            }])}
                            placeholder="Código da aposta"
                            spellCheck={false}
                            value={code}
                            onChange={({ target }) => setCode(target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <div className="w-100 mt-2"></div>
                      <Col>
                        <Button variant="primary" block onClick={() => searchBet()}>
                          <Icon name="search" margin />
                          Buscar
                        </Button>
                      </Col>
                    </Row>
                  </Alert>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )
    } else if (loading) {
      return <PageSpinner isDarkTheme={isDarkTheme} />
    }

    return renderContent()
  }

  const renderButtonGroup = () => {
    if (isMobile) {
      return (
        <>
          <ButtonGroup className="p-2">
            <Button variant="secondary" size="sm" className={type == 1 && 'active'} onClick={() => setType(1)}>
              <Icon name="clock" />
              Aberta
              {bets.length > 0 && type == 1 && <Badge variant="primary" className="w-100 float-right">{bets.length}</Badge>}
            </Button>
            <Button variant="secondary" size="sm" className={type == 2 && 'active'} onClick={() => setType(2)}>
              <Icon name="hand-holding-usd" />
              Ganhou
              {bets.length > 0 && type == 2 && <Badge variant="primary" className="w-100 float-right">{bets.length}</Badge>}
            </Button>
            <Button variant="secondary" size="sm" className={type == 3 && 'active'} onClick={() => setType(3)}>
              <Icon name="sad-tear" />
              Perdeu
              {bets.length > 0 && type == 3 && <Badge variant="primary" className="w-100 float-right">{bets.length}</Badge>}
            </Button>
            <Button variant="secondary" size="sm" className={type == 4 && 'active'} onClick={() => setType(4)}>
              <Icon name="ban" />
              Cancelada
              {bets.length > 0 && type == 4 && <Badge variant="primary" className="w-100 float-right">{bets.length}</Badge>}
            </Button>
            <Button variant="secondary" size="sm" className={isSearch && 'active'} onClick={() => setIsSearch(!isSearch)}>
              {isSearch ?
                <>
                  <Icon name="times" />
                  Fechar
                </>
                :
                <>
                  <Icon name="search" />
                  Buscar
                </>
              }
            </Button>
          </ButtonGroup>
        </>
      )
    }
    return (
      <>
        <ButtonGroup className="w-100">
          <Button variant="secondary" className={type == 1 && 'active'} onClick={() => setType(1)}>
            <Icon name="clock" margin />
            Aberta
            {bets.length > 0 && type == 1 && <Badge variant="primary" className="float-right">{bets.length}</Badge>}
          </Button>
          <Button variant="secondary" className={type == 2 && 'active'} onClick={() => setType(2)}>
            <Icon name="hand-holding-usd" margin />
            Ganhou
            {bets.length > 0 && type == 2 && <Badge variant="primary" className="float-right">{bets.length}</Badge>}
          </Button>
          <Button variant="secondary" className={type == 3 && 'active'} onClick={() => setType(3)}>
            <Icon name="sad-tear" margin />
            Perdeu
            {bets.length > 0 && type == 3 && <Badge variant="primary" className="float-right">{bets.length}</Badge>}
          </Button>
          <Button variant="secondary" className={type == 4 && 'active'} onClick={() => setType(4)}>
            <Icon name="ban" margin />
            Cancelada
            {bets.length > 0 && type == 4 && <Badge variant="primary" className="float-right">{bets.length}</Badge>}
          </Button>
          <Button variant="secondary" className={isSearch && 'active'} onClick={() => setIsSearch(!isSearch)}>
            {isSearch ?
              <>
                <Icon name="times" margin />
                Fechar
              </>
              :
              <>
                <Icon name="search" margin />
                Buscar
              </>
            }
          </Button>
        </ButtonGroup>
      </>
    )
  }

  const renderContainer = () => {
    if (isMobile) {
      return (
        <>
          <div className="home-m-wrapper">
            <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
            <div className="overflow-hidden scroll-mobile pb-0">
              <div className="overflow-x">
                {renderButtonGroup()}
              </div>
              {renderMain()}
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <Navbar active={sport} axios={axios} onItemClick={onItemClick} onSelect={onChangeSport} isDarkTheme={isDarkTheme} />
        <div className="flex-column home-wrapper overflow-hidden">
          <div className="overflow-hidden h-100">
            <div className="m-2 overflow-x">
              {renderButtonGroup()}
            </div>
            {renderMain()}
          </div>
        </div>
      </>
    )
  }

  return renderContainer()
}
